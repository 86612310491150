<template>
	<div class="mb-5">
		<!-- <section id="faq-search-filter">
			<b-card
				no-body
				class="faq-search"
				:style="{
					backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
				}"
			>
				<b-card-body class="text-center">
					<h2 class="text-primary">
						Perguntas Frequentes
					</h2>
					<b-card-text class="mb-2">
						escolha uma categoria para encontrar a ajuda que você precisa rapidamente
					</b-card-text>

					<b-form class="faq-search-input">
						<b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
								<feather-icon icon="SearchIcon" />
							</b-input-group-prepend>
							<b-form-input
								id="searchbar"
								v-model="faqSearchQuery"
								placeholder="Qual a sua dúvida?"
							/>
						</b-input-group>
					</b-form>
				</b-card-body>
			</b-card>
		</section> -->
		<!--/ faq search section -->
		<SelectContract />
		<!-- frequently asked questions tabs pills -->
		<section id="faq-tabs">
			<b-tabs
				vertical
				content-class="col-12 col-md-8 col-lg-9"
				pills
				nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
				nav-class="nav-left"
			>
				<Boleto />

				<!-- payment tab -->
				<b-tab
					v-for="(categoryObj, categoryName, index) in faqData"
					:key="categoryName"
					:active="!index"
				>
					<!-- title -->
					<template #title>
						<feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
						<span class="font-weight-bold">{{ categoryObj.title }}</span>
					</template>

					<faq-question-answer :options="categoryObj" />
				</b-tab>

				<Rocada />
				<!--/ payment tab -->

				<!-- sitting lady image -->
				<template #tabs-end>
					<b-img
						fluid
						:src="require('@/assets/images/illustration/faq-illustrations.svg')"
						class="d-none d-md-block mt-auto"
					/>
				</template>
				<!--/ sitting lady image -->
			</b-tabs>
		</section>
		<!--/ frequently asked questions tabs pills -->
	</div>
</template>

<script>
import {
	// BCard,
	// BCardBody,
	// BForm,
	// BInputGroup,
	// BFormInput,
	// BCardText,
	// BInputGroupPrepend,
	BTabs,
	BTab,
	BImg,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'
import Boleto from './components/Boleto'
import Rocada from './components/Rocada'
import SelectContract from '@/views/components/selectContract.vue'

export default {
	components: {
		// BForm,
		// BCard,
		// BCardBody,
		// BInputGroup,
		// BFormInput,
		// BCardText,
		// BInputGroupPrepend,
		BTabs,
		BTab,
		BImg,
		FaqQuestionAnswer,
		Rocada,
		SelectContract,
		Boleto,
	},
	data() {
		return {
			faqSearchQuery: '',
			faqData: {},
		}
	},
	watch: {
		faqSearchQuery: {
			immediate: true,
			handler() {
				this.fetchData()
			},
		},
	},
	methods: {
		fetchData() {
			this.$http.get('/faq/data', { params: { q: this.faqSearchQuery } }).then(res => {
				this.faqData = res.data
			})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
