<template>
    <b-tab>
        <!-- title -->
        <template #title>
            <feather-icon
                icon="ImageIcon"
                size="18"
                class="mr-1"
            />
            <span class="font-weight-bold">Programa de Roçada</span>
        </template>

        <div class="d-flex align-items-center">
            <b-avatar
                rounded
                size="42"
                variant="light-primary"
                class="mr-1"
            >
                <feather-icon
                icon="ImageIcon"
                size="20"
                />

            </b-avatar>
            <div>
                <h4 class="mb-0">
                    Programa de Roçada
                </h4>
            </div>
        </div>

        <!-- collapse -->
        <app-collapse
            id="faq-payment-qna"
            accordion
            type="margin"
            class="mt-2"
        >

            <app-collapse-item
                title="Preciso roçar meu lote?"
            >
                <p class="text-justify mr-1">
                    É importante você manter seu lote limpo de matos altos, uma vez que agora você tem a posse do imóvel. Para isso, você pode contratar alguém ou aderir ao programa de Roçada da São Bento, caso ele já não esteja incluso no seu contrato.
                </p>
            </app-collapse-item>

            <app-collapse-item
                title="O que é o Programa de Roçada?"
            >
                <p class="text-justify mr-1">
                    É uma solução simples e muito barata que a São Bento encontrou para te ajudar a manter o seu lote sempre limpo. Por um investimento baixo, quase simbólico, nós deixamos seu terreno roçado o ano inteiro, assim você não precisa se preocupar com sujeira, bichos e eventuais multas aplicadas pelo município.
                </p>
            </app-collapse-item>

            <app-collapse-item
                title="Como faço parte do Programa de Roçada?"
            >
                <p class="text-justify mr-1">
                    Existem duas maneiras de você fazer parte do programa de Roçada: 1. Você pode ter comprado um lote parcelado já com o programa incluso em seu contrato; 2. Você pode seguir o seguinte passo a passo abaixo: (Lembrando que - no momento - o programa de roçada está disponível apenas em alguns empreendimentos).
                    <ol>
                        <li>Clique na aba de <b>SOLICITAÇÕES</b>, depois na opção <a href="/programa-rocada"><b>PROGRAMA DE ROÇADA</b></a> e por fim em <b>"QUERO CADASTRAR"</b>.</li>
                        <li>Caso seu contrato atenda as exigências para adesão, você terá a opção de clicar em <b>SOLICITAR PROGRAMA DE ROÇADA</b>.</li>
                        <li>Agora é só acompanhar sua solicitação na aba de <a href="/minhas-solicitacoes"><b>MINHAS SOLICITAÇÕES</b></a>, assim você poderá visualizar o andamento de sua até que a adesão ao programa seja finalizada!</li>
                        <li>
                            Para finalizar, você receberá em seu e-mail o Termo de Adesão para assinatura digital. 
                            E pronto, após a assinatura é só relaxar que a São Bento cuidará do seu lote!
                        </li>
                    </ol>    
                </p>
            </app-collapse-item>

            <app-collapse-item
                title="Qual o valor do Programa de Roçada?"
            >
                <p class="text-justify mr-1">
                    <template v-if="selectedContract.empreendimento_municipio == 'Naviraí' && valores.navirai.ativo == true">
                        <template v-if="selectedContract.situacao == 50">
                            <template v-if="valores.navirai.quitado.tipo == 1">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.navirai.quitado.valor }}
                                <p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
                            </template>
                            <template v-if="valores.navirai.quitado.tipo == 2">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.navirai.quitado.valor }}
                                <p>
                                    Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
                                </p>
                            </template>                            
                        </template>
                        <template v-else>
							<template v-if="valores.navirai.efetivado.tipo == 1">
									Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.navirai.efetivado.valor }}
									<p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
							</template>
							<template v-if="valores.navirai.efetivado.tipo == 2">
									Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.navirai.efetivado.valor }}
									<p>
										Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
									</p>
							</template>
                        </template>                        
                    </template>
                    <template v-else-if="selectedContract.empreendimento_municipio == 'Itaquiraí' && valores.itaquirai.ativo == true">
                        <template v-if="selectedContract.situacao == 50">
                            <template v-if="valores.itaquirai.quitado.tipo == 1">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.itaquirai.quitado.valor }}
                                <p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
                            </template>
                            <template v-if="valores.itaquirai.quitado.tipo == 2">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.itaquirai.quitado.valor }}
                                <p>
                                    Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
                                </p>
                            </template>                            
                        </template>
                        <template v-else>
							<template v-if="valores.itaquirai.efetivado.tipo == 1">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.itaquirai.efetivado.valor }}
                                <p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
                            </template>
                            <template v-if="valores.itaquirai.efetivado.tipo == 2">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.itaquirai.efetivado.valor }}
                                <p>
                                    Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
                                </p>
                            </template>
                        </template>                        
                    </template>
                    <template v-else-if="selectedContract.empreendimento_municipio == 'Ivinhema' && valores.ivinhema.ativo == true">
                        <template v-if="selectedContract.situacao == 50">
                            <template v-if="valores.ivinhema.quitado.tipo == 1">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.ivinhema.quitado.valor }}
                                <p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
                            </template>
                            <template v-if="valores.ivinhema.quitado.tipo == 2">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.ivinhema.quitado.valor }}
                                <p>
                                    Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
                                </p>
                            </template>                            
                        </template>
                        <template v-else>
							<template v-if="valores.ivinhema.efetivado.tipo == 1">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.ivinhema.efetivado.valor }}
                                <p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
                            </template>
                            <template v-if="valores.ivinhema.efetivado.tipo == 2">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.ivinhema.efetivado.valor }}
                                <p>
                                    Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
                                </p>
                            </template>
                        </template>                        
                    </template>
                    <template v-else-if="selectedContract.empreendimento_municipio == 'Caarapó' && valores.caarapo.ativo == true">
                        <template v-if="selectedContract.situacao == 50">
                            <template v-if="valores.caarapo.quitado.tipo == 1">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.caarapo.quitado.valor }}
                                <p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
                            </template>
                            <template v-if="valores.caarapo.quitado.tipo == 2">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.caarapo.quitado.valor }}
                                <p>
                                    Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
                                </p>
                            </template>                            
                        </template>
                        <template v-else>
							<template v-if="valores.caarapo.efetivado.tipo == 1">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.caarapo.efetivado.valor }}
                                <p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
                            </template>
                            <template v-if="valores.caarapo.efetivado.tipo == 2">
                                Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.caarapo.efetivado.valor }}
                                <p>
                                    Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
                                </p>
                            </template>
                        </template>                        
                    </template>
                    <template v-else-if="selectedContract.empreendimento_municipio == 'Dourados' && valores.dourados.ativo == true">
                        <template v-if="selectedContract.empreendimento == 30">
							<template v-if="selectedContract.situacao == 50">
								<template v-if="valores.dourados.monteSiao.quitado.tipo == 1">
									Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.monteSiao.quitado.valor }}
									<p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
								</template>
								<template v-if="valores.dourados.monteSiao.quitado.tipo == 2">
									Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.monteSiao.quitado.valor }}
									<p>
										Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
									</p>
								</template>                            
							</template>
							<template v-else>
								<template v-if="valores.dourados.monteSiao.efetivado.tipo == 1">
										Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.monteSiao.efetivado.valor }}
										<p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
									</template>
									<template v-if="valores.dourados.monteSiao.efetivado.tipo == 2">
										Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.monteSiao.efetivado.valor }}
										<p>
											Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
										</p>
									</template>
								</template>                        
							</template>
                        <template v-else>
							<template v-if="selectedContract.situacao == 50">
								<template v-if="valores.dourados.cidadeJardim.quitado.tipo == 1">
									Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.cidadeJardim.quitado.valor }}
									<p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
								</template>
								<template v-if="valores.dourados.cidadeJardim.quitado.tipo == 2">
									Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.cidadeJardim.quitado.valor }}
									<p>
										Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
									</p>
								</template>                            
							</template>
							<template v-else>
								<template v-if="valores.dourados.cidadeJardim.efetivado.tipo == 1">
										Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.cidadeJardim.efetivado.valor }}
										<p>O valor deve ser pago em uma parcela única, no momento da adesão e renovada anualmente.</p>
									</template>
									<template v-if="valores.dourados.cidadeJardim.efetivado.tipo == 2">
										Para seu contrato selecionado {{ selectedContract.contrato }} o valor anual é {{ valores.dourados.cidadeJardim.efetivado.valor }}
										<p>
											Esse total é parcelado em 12x de R$20,00 e incluso nos boletos da sua parcela, ou seja, menos de R$0,70 por dia.
										</p>
									</template>
								</template>                        
							</template>
                        </template>                        
                    <template v-else>
                        No momento o Programa de Roçada não está liberado para o empreendimento do contrato selecionado {{ selectedContract.contrato }}. 
                    </template>                                                                                           
                </p>
            </app-collapse-item>
            
            <app-collapse-item
                title="O que acontece se eu não fizer a roçada do meu lote?"
            >
                <p class="text-justify mr-1">
                    A responsabilidade pela limpeza e roçada do seu lote é do proprietário, no caso, você, conforme o contrato de Compra e Venda que temos celebrado. Seu lote com mato alto estará sujeito a notificações e multas Municipais com valor alto.
                </p>
            </app-collapse-item>            
        </app-collapse>        

        
    </b-tab>
</template>

<script>
import store from '@/store'
import {
	BTab,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BAvatar } from 'bootstrap-vue'

export default {

	components: {
		BTab,
		AppCollapse,
		AppCollapseItem,
		BAvatar
	},
    data() {
        return {
            valores: {
                dourados: {
                    ativo: true,
                    cidadeJardim: {
                        quitado: {
                            valor: 'R$ 360.00',
                            tipo: 1
                        },
                        efetivado: {
                            valor: 'R$ 240.00',
                            tipo: 2
                        }                         
                    },
                    monteSiao: {
                        quitado: {
                            valor: 'R$ 360.00',
                            tipo: 1
                        },
                        efetivado: {
                            valor: 'R$ 240.00',
                            tipo: 1
                        }                        
                    }
                },
                caarapo: {
                    ativo: true,
                    quitado: {
                        valor: 'R$ 360.00',
                        tipo: 1
                    },
                    efetivado: {
                        valor: 'R$ 240.00',
                        tipo: 2
                    }
                },
                ivinhema: {
                    ativo: true,
                    quitado: {
                        valor: 'R$ 360.00',
                        tipo: 1
                    },
                    efetivado: {
                        valor: 'R$ 240.00',
                        tipo: 2
                    }
                },
                itaquirai: {
                    ativo: true,
                    quitado: {
                        valor: 'R$ 360.00',
                        tipo: 1
                    },
                    efetivado: {
                        valor: 'R$ 240.00',
                        tipo: 2
                    }
                },
                navirai: {
                    ativo: true,
                    quitado: {
                        valor: 'R$ 360.00',
                        tipo: 1
                    },
                    efetivado: {
                        valor: 'R$ 240.00',
                        tipo: 2
                    }
                },
                maracaju: {
                    ativo: false
                },
                saoGabriel: {
                    ativo: false
                },
                guaranta: {
                    ativo: false
                }                 
            }
        }
    },
	computed: {
		selectedContract() {
			return store.state.contracts.selectedContract
		},
	},
}
</script>