<template>
	<b-tab :active="false">
		<!-- title -->
		<template #title>
			<feather-icon icon="FileTextIcon" size="18" class="mr-1" />
			<span class="font-weight-bold">Boleto</span>
		</template>

		<div class="d-flex align-items-center">
			<b-avatar rounded size="42" variant="light-primary" class="mr-1">
				<feather-icon icon="FileTextIcon" size="20" />
			</b-avatar>
			<div>
				<h4 class="mb-0">
					Boleto
				</h4>
			</div>
		</div>

		<!-- collapse -->
		<app-collapse id="faq-payment-qna" accordion type="margin" class="mt-2">
			<app-collapse-item title="Como pago minhas parcelas?">
				<p class="text-justify mr-1">
					Nos primeiros dias de cada mês, você receberá uma fatura via e-mail com o
					detalhamento dos pagamentos a serem realizados naquele mês e ao final o boleto
					para realizar o pagamento ou agendamento.
				</p>
			</app-collapse-item>

			<app-collapse-item
				title="Como faço para emitir a segunda via ou boleto atualizado?"
			>
				<p class="text-justify mr-1">
					Você tem acesso a segunda via dos seus boletos na aba
					<a href="/financiamento">Financiamento</a> do Portal do Cliente. É só clicar em
					"Emitir Boleto" da parcela que deseja.<br /><br />
					Segue um vídeo tutorial de apenas 11 segundos de
					<a target="_blank" href="https://www.youtube.com/watch?v=lBmpOLyOH7Q"
						>Como gerar a segunda via do seu boleto pelo Portal do Cliente:</a
					>.

					<br />
					<br />

					<iframe
						width="560"
						height="315"
						class="d-none d-md-block mx-auto"
						src="https://www.youtube.com/embed/lBmpOLyOH7Q"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>

					<iframe
						width="100%"
						class="d-md-none mx-auto"
						src="https://www.youtube.com/embed/lBmpOLyOH7Q"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</p>
			</app-collapse-item>

			<app-collapse-item title="Como faço para emitir meu(s) boleto(s) em atraso?">
				<p class="text-justify mr-1">
					Você tem acesso ao(s) seu(s) boleto(s) em atraso na aba
					<a href="/financiamento">Financiamento</a>.
				</p>
				<p class="text-justify mr-1">
					Você pode optar por clicar em BOLETO para que ele seja emitido ou em COPIAR para
					que o código de barras seja copiado.
				</p>
				<p class="text-justify mr-1">
					E pronto, basta seguir para o pagamento presencial, em uma casa Lotérica ou via
					aplicativo, através do banco de sua preferência.
				</p>
				<p class="text-justify mr-1">
					Caso tenha alguma dificuldade, entre em contato com a nossa Equipe de
					Atendimento para te auxiliar, é só clicar no Botão "Fale com um atendente" aqui
					no site, ou através do
					<a href="https://api.whatsapp.com/send?phone=5567998222440" target="_blank">
						Whatsapp </a
					>.
				</p>
				<p class="text-justify mr-1">
					Segue um vídeo tutorial de Como emitir um boleto atrasado pelo Portal do
					Cliente.
				</p>
				<iframe
					width="560"
					height="315"
					class="d-none d-md-block mx-auto"
					src="https://www.youtube.com/embed/mWeNN8jFG_I"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>

				<iframe
					width="100%"
					class="d-md-none mx-auto"
					src="https://www.youtube.com/embed/mWeNN8jFG_I"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</app-collapse-item>

			<app-collapse-item title="E se eu não pagar meu(s) boleto(s) em atraso?">
				<p class="text-justify mr-1">
					Conforme previsto em seu contrato, em casos de falta de pagamento, a partir de 3
					parcelas em atraso, o seu contrato pode ser distratado. Por isso, busque sempre
					manter a situação financeira do seu lote regularizada.
				</p>
			</app-collapse-item>

			<app-collapse-item
				title="O valor da minha parcela mensal mudou, o que aconteceu?
"
			>
				<p class="text-justify mr-1">
					O que ocorre é que em um financiamento, onde não há parcelas fixas, a cada 12
					meses o contrato é <b>reajustado</b>. Para cada contrato há uma taxa fixa de
					reajuste da São Bento, somada a aplicação do percentual de IGPM ou IPCA, onde
					ambos estão previstos em contrato.
				</p>
				<p class="text-justify mr-1">
					Ainda tem dúvidas sobre o seu reajuste? Consulte um de nossos atendentes pelo
					Chat do Portal ou pelo
					<a href="https://api.whatsapp.com/send?phone=5567998222440" target="_blank">
						Whatsapp </a
					>.
				</p>
				<p class="text-justify mr-1">
					Segue um vídeo explicativo sobre Reajuste do seu financiamento:
				</p>
				<iframe
					width="560"
					height="315"
					class="d-none d-md-block mx-auto"
					src="https://www.youtube.com/embed/KcJ9rUf3mxA"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>

				<iframe
					width="100%"
					class="d-md-none mx-auto"
					src="https://www.youtube.com/embed/KcJ9rUf3mxA"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</app-collapse-item>
		</app-collapse>
	</b-tab>
</template>

<script>
import { BTab } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BAvatar } from 'bootstrap-vue'

export default {
	components: {
		BTab,
		AppCollapse,
		AppCollapseItem,
		BAvatar,
	},
}
</script>
